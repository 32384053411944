/**
 * NOTE: There's a big ol' stanky bug with styled-components where dynamically
 * updating styles based on prop changes rerenders the entire stylesheet,
 * including font-face declarations.
 *
 * Render this as separate CSS so it will be added separately from the
 * styled-components tag.
 *
 * https://github.com/styled-components/styled-components/issues/400
 */

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/Neue\ Helvetica®\ Pro\ Complete\ Family/Linotype - HelveticaNeueLTPro-Lt.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/Neue\ Helvetica®\ Pro\ Complete\ Family/Linotype - HelveticaNeueLTPro-Lt.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../fonts/Neue\ Helvetica®\ Pro\ Complete\ Family/Linotype - HelveticaNeueLTPro-Md.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}
